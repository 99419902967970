import { CONTAINER_FILLING_STATUS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Status" } }, [_c("text-field", { key: "Code", attrs: { "data-index": "code", "title": "Status", "width": 150, "sorter": true } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Description" } }), _c("text-field", { key: "bpcsDisposition", attrs: { "data-index": "bpcsDisposition", "title": "BPCSDisposition", "width": 150, "sorter": true } }), _c("icon-field", { key: "Quarantine", attrs: { "data-index": "quarantine", "title": "Quarantine", "align": "center", "width": 150, "render-icon": _vm.renderIcon, "icon-style": _vm.styleObject } }), _c("icon-field", { key: "Conditional", attrs: { "data-index": "conditional", "title": "Conditional", "align": "center", "width": 150, "render-icon": _vm.renderIcon, "icon-style": _vm.styleObject } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListStatus",
  data() {
    return {
      styleObject: {
        fontSize: "16px"
      }
    };
  },
  methods: {
    renderIcon(value) {
      if (!value) {
        return "";
      }
      return "check-circle";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListStatus = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-status" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": "cf.statuses", "api-url": _vm.apiUrl, "create-route": "/container-filling/status/create", "edit-route": "/container-filling/status/:id" } }, [_c("list-status")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListStatus
  },
  data() {
    return {
      page: CONTAINER_FILLING_STATUS,
      ListStatus,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "status",
          title: "Status",
          path: "/container-filling/status"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
